/* eslint-disable import/order */
import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { CaptureConsole, ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"6.16.1",
    dsn:"https:\u002F\u002Fcee34ccd8ef54d6890109c8cfa6880af@o1175160.ingest.sentry.io\u002F6272364",
    environment:"Build",
    debug:false,
  }

  config.integrations = [
    new ExtraErrorData({ depth:4 }),
    new ReportingObserver(),
    new RewriteFrames(),
    new CaptureConsole({ levels:["warn","error"] }),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
