import debug, { Debugger } from 'debug';

// @ts-ignore
const createDefaultLogger = (level: string) => createLogger(level, console[level].bind(console));

const captureSentryMessage = (sentry: any, level: string, msg: any[]) => {
	if (sentry) {
		let severity: string | null;
		switch (level) {
			case 'error':
				severity = 'error';
				break;
			case 'warn':
				severity = 'warning';
				break;
			default:
				severity = null;
		}
		if (!severity) return;

		if (Array.isArray(msg)) {
			msg.forEach((msgItem) => captureSentrySafeException(sentry, msgItem));
		} else {
			captureSentrySafeException(sentry, msg);
		}
	}
};

const captureSentrySafeException = (sentry: any, msg: any) => {
	if (msg instanceof Error) {
		sentry.captureException(msg);
	} else {
		sentry.captureException(new Error(`Non-error object captured: ${JSON.stringify(msg)}`));
	}
};

const logServerSide = (level: string, msg: any[]) => {
	(process as any).winstonLog.log(level, msg);
	captureSentryMessage((process as any).sentry, level, msg);
};

const logClientSide = (level: string, msg: any[], logger: Debugger['log']) => {
	logger.apply(null, msg);
	captureSentryMessage((window as any).sentry, level, msg);
};

const createLogger = (name: string, log: typeof console.log): ((...messages: any[]) => void) => {
	if ((process as any).server) {
		const winstonLog = (process as any).winstonLog;

		if (Object.keys(winstonLog.levels).includes(name)) {
			return (msg) => logServerSide(name, msg);
		}

		return (msg) => logServerSide('info', msg);
	}

	const logger = debug(`app:${name}`);

	logger.log = log.bind(console);

	return (...msg) => logClientSide(name, msg, logger);
};

export default {
	debug: createDefaultLogger('debug'),
	error: createDefaultLogger('error'),
	info: createDefaultLogger('info'),
	trace: createDefaultLogger('trace'),
	warn: createDefaultLogger('warn'),
	createLogger: (name: string) => createLogger(name, console.log),
};
