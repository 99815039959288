import _get from 'lodash-es/get';
import { ActionContext, Store } from 'vuex';
import { RouteRecord } from 'vue-router';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';
import { MediaLoading } from '@/node_modules/@osp/design-system/types/media';
import { Link, TARGET } from '@/node_modules/@osp/design-system/types/link';
import { FONTCOLOR, FONTSIZE } from '@/node_modules/@osp/design-system/types/text';
import { BrandListModel } from '@/node_modules/@osp/design-system/components/BrandList/BrandList.props';
import { CampaignTeaserModel } from '@/node_modules/@osp/design-system/components/CampaignTeaser/CampaignTeaser.props';
import { CategoryListModel } from '@/node_modules/@osp/design-system/components/CategoryList/CategoryList.props';
import { CategorySliderModel } from '@/node_modules/@osp/design-system/components/CategorySlider/CategorySlider.props';
import { ImageTileModel } from '@/node_modules/@osp/design-system/components/ImageTile/ImageTile.props';
import { ImageTileWithDescriptionModel } from '@/node_modules/@osp/design-system/components/ImageTileWithDescription/ImageTileWithDescription.props';
import {
	PromitionTeaserVariant,
	PromotionTeaserModel,
} from '@/node_modules/@osp/design-system/components/PromotionTeaser/PromotionTeaser.props';
import { CalloutBoxModel } from '@/node_modules/@osp/design-system/components/CalloutBox/CalloutBox.props';
import { TestimonialModel } from '@/node_modules/@osp/design-system/components/Testimonial/Testimonial.props';
import { UspBarModel } from '@/node_modules/@osp/design-system/components/UspBar/UspBar.props';
import { RichTextModel } from '@/node_modules/@osp/design-system/components/RichText/RichText.props';
import { SliderModel } from '@/node_modules/@osp/design-system/components/Slider/Slider.props';
import {
	SectionLayoutBackgroundPosition,
	SectionLayoutComponentData,
	SectionLayoutData,
	SectionLayoutRowType,
} from '~/@types/layout';
import {
	CMSCONTENT_A_LOAD_SINGLE_COMPONENT,
	CMSCONTENT_A_UPDATE,
	CMSCONTENT_G_DEREFERENCE,
	CMSCONTENT_G_REFERENCE_CONTENT,
	CMSCONTENT_G_SLOT_CONTENT,
	CMSCONTENT_G_SLOT_HAS_CONTENT,
	CMSCONTENT_M_SAVE,
	mapFn,
} from '~/@constants/store';
import { getJson } from '~/app-utils/http';
import { mapRamlBrand } from '~/assets/js/mapper/brand';
import { mapRamlCategory } from '~/assets/js/mapper/category';
import { mapImage, mapImageWrapper } from '~/assets/js/mapper/image';
import { backend } from '~/@api/backend';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useDynamicyieldStore } from '~/@api/store/dynamicyieldApi';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useUserStore } from '~/@api/store/userApi';
import { useVoucherStore } from '~/@api/store/voucherApi';
import {
	CmsBackendContentEntry,
	CmsContentEntryReference,
	CmsContentState,
	CmsDereferencedEntry,
	CmsTeaserButtonLinkTarget,
	DynamicYieldVariation,
	MediaqueryDeviceCategory,
	RootState,
} from '~/@api/store.types';
import {
	Bar,
	BrandTeaserComponent,
	Button,
	CampaignTeaserComponent,
	CategorySliderComponent,
	CategoryTeaserComponent,
	DYProductRecommendationComponent,
	GridLayout,
	GridLayoutData,
	GridLayoutRow,
	GridLayoutRowType,
	ImageTeaserComponent,
	PromotionTeaserComponent,
	SliderTeaserData,
	SpaType,
	TeaserDataList,
	TestimonialTeaserComponent,
	Text,
	TextComponent,
} from '~/generated/hybris-raml-api';
import { DYBrandListModel } from '~/components/dynamic-yield/dy-brand-list/dy-brand-list.props';
import { DYCategoryListModel } from '~/components/dynamic-yield/dy-category-list/dy-category-list.props';
import { DYProductRecommendationModel } from '~/components/dynamic-yield/dy-product-recommendation/dy-product-recommendation.props';
import { SectionLayoutDataListModel } from '~/components/section-layout/section-layout__data-list.props';
import { SectionLayoutModel } from '~/components/section-layout/section-layout.props';
import { eecPromotionView } from '~/tracking/events/eec.promotionView';
import { eecPromotionClick } from '~/tracking/events/eec.promotionClick';
import { getDYPageContext } from '~/tracking/trackingutils';
import { importLogger, importRunTask } from '~/app-utils/dynamic-imports';

// Utility function(s) -----------------------------------------------------------------------------

const getSlotsWithReference = (
	slots: CmsContentState['slots'],
	references: CmsContentState['references'],
): CmsContentState['slots'] => {
	const refKeys = Object.keys(references);

	return Object.assign(
		{},
		...Object.entries(slots).map(([slotName, slotRefs]) => ({
			[slotName]: slotRefs.filter((ref) => refKeys.includes(ref.key)),
		})),
	);
};

// Initial state -----------------------------------------------------------------------------------

const state = () => ({
	contentGender: null,
	references: {},
	slots: {},
});

// Mutations ---------------------------------------------------------------------------------------

const mutations = {
	[mapFn(CMSCONTENT_M_SAVE)](_state: CmsContentState, newState: CmsContentState) {
		_state.contentGender = newState.contentGender;
		_state.references = {
			..._state.references,
			...newState.references,
		};
		_state.slots = getSlotsWithReference(newState.slots, _state.references);
	},
};

// Actions -----------------------------------------------------------------------------------------

const actions = {
	async [mapFn(CMSCONTENT_A_LOAD_SINGLE_COMPONENT)](
		_context: ActionContext<CmsContentState, RootState>,
		componentUid: string,
	) {
		try {
			const response = await getJson(
				backend.API.V2.COMPONENTS.SINGLE_COMPONENT(this, componentUid),
				this,
			);

			return response.json;
		} catch (exception) {
			importLogger().then(({ default: Logger }) => {
				Logger.error(CMSCONTENT_A_LOAD_SINGLE_COMPONENT, exception);
			});
		}
	},

	async [mapFn(CMSCONTENT_A_UPDATE)](
		context: ActionContext<CmsContentState, RootState>,
		payload: {
			spaType: SpaType;
			identifier: string;
			full: boolean;
			location?: string;
			routeMatched?: RouteRecord[];
		},
	) {
		try {
			if (payload.spaType === SpaType.other && payload.identifier === 'unknown') return;

			const currentGender = useUserStore(this).state.user.gender;
			const hasSlots = Object.keys(context.state.slots || {}).length > 0;
			const fetchFull = payload.full || currentGender !== context.state.contentGender || !hasSlots;

			const response = await getJson(
				backend.API.V2.COMPONENTS.PAGE(
					this,
					payload.spaType,
					payload.identifier,
					payload.location,
					fetchFull,
					process.server,
				),
				this,
				{},
				true,
				'manual',
			);

			// Redirects
			// For example: if the requested product url holds the id of a different product
			if ([301, 302].includes(response.status)) {
				return response;
			}

			const cmscontent = response.json as CmsContentState;
			cmscontent.contentGender = currentGender;

			// Loading optimization - extract all campaign names of dynamic yield content entries
			// for single API request
			if (useServerContextStore(this).state.session.trackingEnabled) {
				await prefechtDynamicYieldCampaigns(this, cmscontent.references, payload.routeMatched);
			}

			context.commit(mapFn(CMSCONTENT_M_SAVE), cmscontent);
		} catch (exception) {
			importLogger().then(({ default: Logger }) => {
				Logger.error(exception);
				Logger.error(CMSCONTENT_A_UPDATE, exception);
			});
		}

		elapsedTime(CMSCONTENT_A_UPDATE);
	},
};

// Getters -----------------------------------------------------------------------------------------

const getters = {
	[mapFn(CMSCONTENT_G_DEREFERENCE)](_state: CmsContentState) {
		return (store: Store<RootState>, ref: CmsContentEntryReference): CmsDereferencedEntry => {
			const content = ref !== undefined ? _get(_state.references, ref.key) : undefined;

			if (content) {
				const backendComponentName = ref.key.split('__')[0];

				return mapData(store, backendComponentName, content, ref);
			}
		};
	},

	[mapFn(CMSCONTENT_G_REFERENCE_CONTENT)](_state: CmsContentState) {
		return (id: string): CmsBackendContentEntry => _get(_state.references, id);
	},

	[mapFn(CMSCONTENT_G_SLOT_CONTENT)](_state: CmsContentState) {
		return (id: string): CmsContentEntryReference[] => _get(_state.slots, id, []);
	},

	[mapFn(CMSCONTENT_G_SLOT_HAS_CONTENT)](_state: CmsContentState) {
		return (id: string): boolean => _get(_state.slots, id, []).length > 0;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};

// DynamicYield prefetch campaign content ----------------------------------------------------------

// Check for DynamicYield components and batch request their content
const prefechtDynamicYieldCampaigns = async (
	store: Store<RootState>,
	references: { [componentName: string]: CmsBackendContentEntry },
	routeMatched: RouteRecord[],
) => {
	// Add 'PromoBar' as default as DyPromoBar component is included in the header
	// so it is present and prefetched on all pages
	// (to activate optimization for prefeteched A/B test variants, ensure selector has "AB-Test" in its name)
	const dySelectors = ['PromoBar', 'PLP-AddToCart-AB-Test'];
	const isClubMemberWithBonus =
		useUserStore(store).state.user.clubId &&
		useVoucherStore(store).state.userGiftBonusCardTotals.value;

	if (isClubMemberWithBonus) {
		dySelectors.push('Club-Bonus-AB-Test');
	}

	// pages without cmscontent don't have `references`
	if (references) {
		for (const [componentName, contentEntry] of Object.entries(references)) {
			// Define CMS entry keys that are DynamicYield related and collect their campaign selector name

			const referenceUid = 'uid' in contentEntry ? contentEntry.uid.toLowerCase() : '';

			if (
				// Check for all existing cmsContent DY components that should be prefetched
				componentName.includes('DYProductRecommendationComponent') &&
				'selectorName' in contentEntry &&
				!referenceUid.startsWith('middle') &&
				!referenceUid.startsWith('bottom')
			) {
				dySelectors.push(contentEntry.selectorName);
			}
		}
	}

	try {
		const { yieldToMain } = await importRunTask();

		await yieldToMain();

		await useDynamicyieldStore(store).api.fetch({
			selector: dySelectors,
			pageContext: getDYPageContext(routeMatched || [], store),
			forceUpdate: true,
		});

		await yieldToMain();

		// store prefetched AB test data
		storePrefetchedDyAbTestData(store, dySelectors);
	} catch (error) {
		importLogger().then(({ default: Logger }) => {
			Logger.error(`[DY] Error: ${error}`);
		});
	}
};

function storePrefetchedDyAbTestData(store: Store<RootState>, dySelectors: string[]) {
	const { api: dynamicyieldApi, state: dynamicyieldState } = useDynamicyieldStore(store);

	dySelectors
		.filter((selectorName) => selectorName.toLowerCase().includes('ab-test'))
		.forEach((selectorName) => {
			const choiceKey = dynamicyieldApi.buildDyDataKey(selectorName);
			const dyVariation = (
				dynamicyieldState.choices[choiceKey]?.variations as DynamicYieldVariation[]
			)?.find((variation) => variation?.payload?.type === 'CUSTOM_JSON');

			if (dyVariation) {
				importRunTask().then(({ runTask }) => {
					runTask(() => dynamicyieldApi.storeData(choiceKey, dyVariation.payload?.data?.variant));
				});
			}
		});
}

// Frontend-Data-Mapping for CMS components --------------------------------------------------------

const mapTarget = (target: string) =>
	target === CmsTeaserButtonLinkTarget.NEWWINDOW ? TARGET.BLANK : TARGET.SELF;

const mapData = (
	store: Store<RootState>,
	backendComponentName: string,
	content: CmsBackendContentEntry,
	ref: CmsContentEntryReference,
): CmsDereferencedEntry => {
	const mapper = mappers[backendComponentName];
	const id = ref.key;

	if (mapper) {
		return {
			component: mapper.getComponentName(content) || backendComponentName,
			content: mapper.map(store, content, 1),
			id,
		};
	}

	return { component: backendComponentName, content, id };
};

interface DataMapper {
	map: (
		store: Store<RootState>,
		content: CmsBackendContentEntry,
		elementsInRow: number,
	) => SectionLayoutComponentData | UspBarModel;
	getComponentName: (content: CmsBackendContentEntry) => string;
	isDynamicYieldComponent: (content: CmsBackendContentEntry) => boolean;

	[mapping: string]: any;
}

// Component mappers -------------------------------------------------------------------------------

// BRAND-LIST
const BrandTeaserComponentMapper: DataMapper = {
	getComponentName: (content: CmsBackendContentEntry) =>
		(content as BrandTeaserComponent).showDYAffinities ? 'DyBrandList' : 'BrandList',
	isDynamicYieldComponent: (content: CmsBackendContentEntry) =>
		(content as BrandTeaserComponent).showDYAffinities,
	map: (
		store: Store<RootState>,
		content: BrandTeaserComponent,
	): BrandListModel | DYBrandListModel => {
		const data = {
			uid: content.uid,
			tracking: {
				view: (data) =>
					eecPromotionView([BrandTeaserComponentMapper.mapTrackingData(content, data)]),
				click: (data) =>
					eecPromotionClick(BrandTeaserComponentMapper.mapTrackingData(content, data)),
			},
		};
		const brands = content.brands.map((brand) => mapRamlBrand(store, brand));

		return content.showDYAffinities ? { ...data, fallbackBrands: brands } : { ...data, brands };
	},
	mapTrackingData: (content: BrandTeaserComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// BAR
const BarComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'UspBar',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (_store: Store<RootState>, content: Bar): UspBarModel => ({
		closable: content.closable,
		cookieExpires: content.cookieExpires,
		items: content.banners.map((banner) => ({
			icon: banner.iconId,
			content: {
				text: banner.text,
			},
		})),
	}),
};

// CATEGORY-LIST
const CategoryTeaserComponentMapper: DataMapper = {
	getComponentName: (content: CmsBackendContentEntry) =>
		(content as CategoryTeaserComponent).showDYAffinities ? 'DyCategoryList' : 'CategoryList',
	isDynamicYieldComponent: (content: CmsBackendContentEntry) =>
		(content as CategoryTeaserComponent).showDYAffinities,
	map: (
		store: Store<RootState>,
		content: CategoryTeaserComponent,
	): CategoryListModel | DYCategoryListModel => {
		const data = { uid: content.uid };
		const categories = content.categories.map((category) => mapRamlCategory(store, category));

		return content.showDYAffinities
			? { ...data, fallbackCategories: categories }
			: { ...data, categories };
	},
};

// CATEGORY-SLIDER
const CategorySliderMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'CategorySlider',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (store: Store<RootState>, content: CategorySliderComponent): CategorySliderModel => {
		const defaultImageWidth = 322;
		const imageBreakpoints = {
			'1024': 364,
			'768': 322,
			'376': 364,
		};

		return {
			uid: content.uid,
			categories: content.categories.map((category) =>
				mapRamlCategory(store, category, defaultImageWidth, imageBreakpoints),
			),
			brand: !content.brand
				? undefined
				: {
						uid: `${content.uid}__brandItem`,
						name: content.brand?.name,
						logo: content.brand?.image
							? mapImage(store, content.brand.image, imageBreakpoints, { width: defaultImageWidth })
							: undefined,
						link: {
							text: content.brand?.name,
							href: content.brand?.url,
						},
					},
		};
	},
	mapTrackingData: (content: CategorySliderComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// CAMPAIGN-TEASER
const CampaignTeaserComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'CampaignTeaser',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (store: Store<RootState>, content: CampaignTeaserComponent): CampaignTeaserModel => {
		const { state: mediaqueryState } = useMediaqueryStore(store);
		return {
			uid: content.uid,
			...mapImageWrapper(
				store,
				content.images,
				{ 768: 1024, 1024: 1280, 1280: 1536, 1536: 1820 },
				768,
				true,
			),
			headline: mapText(content.headline, mediaqueryState.device?.category),
			link: {
				href: content.button?.url,
				target: mapTarget(content.button?.target),
				text: content.button?.text,
			},
			tracking: {
				view: (data) =>
					eecPromotionView([CampaignTeaserComponentMapper.mapTrackingData(content, data)]),
				click: (data) =>
					eecPromotionClick(CampaignTeaserComponentMapper.mapTrackingData(content, data)),
			},
		};
	},
	mapTrackingData: (content: CampaignTeaserComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// DY-PRODUCT-RECOMMENDATION
const DYProductRecommendationMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'DyProductRecommendation',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => true,
	map: (
		_store: Store<RootState>,
		content: DYProductRecommendationComponent,
	): DYProductRecommendationModel => ({
		uid: content.uid,
		autoFetch: false,
		options: {
			hasArrows: true,
			slidesPerView: content.visibleDatasMobile,
			slidesPerViewTablet: content.visibleDatasTablet,
			slidesPerViewDesktop: content.visibleDatasDesktop,
		},
		selectorName: content.selectorName,
		tracking: {
			view: (data) =>
				eecPromotionView([DYProductRecommendationMapper.mapTrackingData(content, data)]),
			click: (data) =>
				eecPromotionClick(DYProductRecommendationMapper.mapTrackingData(content, data)),
		},
	}),
	mapTrackingData: (content: DYProductRecommendationComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// IMAGE-TILE (-WITH-DESCRIPTION)
const ImageTeaserComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) =>
		(_content as ImageTeaserComponent).text ? 'ImageTileWithDescription' : 'ImageTile',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (
		store: Store<RootState>,
		content: ImageTeaserComponent,
		elementsInRow = 1,
	): ImageTileModel | ImageTileWithDescriptionModel => ({
		image: content.isSquare
			? mapImage(
					store,
					content.image,
					{ 375: 450, 768: Math.ceil(1175 / elementsInRow), 1280: Math.ceil(1152 / elementsInRow) },
					{ width: 350, square: true, ignoreRetina: true },
					true,
				)
			: mapImage(
					store,
					content.image,
					{
						375: Math.ceil(500 / elementsInRow),
						768: Math.ceil(1255 / elementsInRow),
						1280: Math.ceil(1487 / elementsInRow),
						1536: Math.ceil(1740 / elementsInRow),
					},
					{ width: Math.ceil(350 / elementsInRow), ignoreRetina: true },
					true,
				),
		imageLoading: MediaLoading.LAZY,
		isSquare: content.isSquare,
		url: content.url,
		headline: content.headline,
		icon: content.icon,
		text: content.text,
		title: content.title,
		uid: content.uid,
		tracking: {
			view: (data) => eecPromotionView([ImageTeaserComponentMapper.mapTrackingData(content, data)]),
			click: (data) => eecPromotionClick(ImageTeaserComponentMapper.mapTrackingData(content, data)),
		},
	}),
	mapTrackingData: (content: ImageTeaserComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// PROMOTION-TEASER
const PromotionTeaserComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'PromotionTeaser',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (store: Store<RootState>, content: PromotionTeaserComponent): PromotionTeaserModel => {
		const { state: mediaqueryState } = useMediaqueryStore(store);
		return {
			...mapImageWrapper(
				store,
				content.images,
				{ 768: 1024, 1024: 1280, 1280: 1536, 1536: 1820 },
				768,
				// ignore retina if it's just a background image
				mediaqueryState.device.category === MediaqueryDeviceCategory.MOBILE &&
					!!content.backgroundColor,
				true,
			),
			link: {
				href: content.button?.url,
				target: mapTarget(content.button?.target),
				text: content.button?.text,
			},
			preHeadline: mapText(content.preHeadline, mediaqueryState.device?.category),
			headline: mapText(content.headline, mediaqueryState.device?.category),
			subline: mapText(content.subline, mediaqueryState.device?.category),
			overlayColor: content.backgroundColor,
			variant: PromitionTeaserVariant[content.variant],
			uid: content.uid,
			tracking: {
				view: (data) =>
					eecPromotionView([PromotionTeaserComponentMapper.mapTrackingData(content, data)]),
				click: (data) =>
					eecPromotionClick(PromotionTeaserComponentMapper.mapTrackingData(content, data)),
			},
		};
	},
	mapTrackingData: (content: PromotionTeaserComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// PROMOTION-TEXT-BOX
const PromotionTextDataMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'CalloutBox',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (_store: Store<RootState>, content: any): CalloutBoxModel => ({
		uid: content.uid,
		title: content.title,
		subTitle: content.text,
		details: content.legalText,
	}),
};

// SECTION-LAYOUT
const SectionLayoutMappers = {
	utils: {
		filterDatas: (store: Store<RootState>, datas: GridLayoutData[]) =>
			datas
				.filter((data) => SectionLayoutMappers.utils.filterDataByDevice(store, data))
				.filter(SectionLayoutMappers.utils.filterDataByShortage),
		filterDataByDevice: (store: Store<RootState>, data: GridLayoutData) =>
			!data.deviceShortage ||
			(data.deviceShortage.devices || []).includes(
				useServerContextStore(store).state.userAgent.deviceCategory === 'desktop'
					? 'DESKTOP'
					: 'MOBILE',
			),
		filterDataByShortage: (data: GridLayoutData) => {
			const time: number = Date.now();

			return (
				!data.expiringShortage ||
				!(
					Number(data.expiringShortage.startTime || Number.MIN_VALUE) > time ||
					Number(data.expiringShortage.endTime || Number.MAX_VALUE) < time
				)
			);
		},
		mapDatas: (store: Store<RootState>, datas: GridLayoutData[], elementsInRow = 0) =>
			datas
				.map((dataWrapper): SectionLayoutData => {
					const reference = dataWrapper.data as CmsContentEntryReference;
					const mapper = mappers[reference.key.split('__')[0]];

					if (mapper) {
						const rawData = _get(useCmsContentStore(store).state.references, reference.key);
						const componentData = mapper.map(
							store,
							rawData,
							elementsInRow || datas.length,
						) as SectionLayoutComponentData;

						return {
							componentData,
							componentName: mapper.getComponentName(rawData),
							id: reference.key,
							isDynamicYieldComponent: mapper.isDynamicYieldComponent(rawData),
						};
					}

					return null;
				})
				.filter((data) => !!data),
	},

	SectionLayoutComponentMapper: {
		getComponentName: (_content: CmsBackendContentEntry) => 'SectionLayout',
		isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
		map: (store: Store<RootState>, content: GridLayout): SectionLayoutModel => ({
			backgroundPosition: SectionLayoutBackgroundPosition[content['background-position']],
			ctaButton: SectionLayoutMappers.SectionLayoutComponentMapper.mapCTAButton(content.ctaButton),
			headline: content.headline,
			headlinePosition: SectionLayoutRowType[content.type],
			uid: content.code,
			rows: SectionLayoutMappers.SectionLayoutComponentMapper.mapRows(store, content),
		}),
		mapCTAButton: (button: Button): Link => {
			if (!button) {
				return null;
			}

			return {
				text: button.text,
				href: button.url,
				target: mapTarget(button.target),
			};
		},
		mapRows: (store: Store<RootState>, content: GridLayout) => {
			const filteredDatas = SectionLayoutMappers.utils.filterDatas(store, content.datas);
			let start = 0;

			return content.rows
				.map((row: GridLayoutRow) => {
					const lastIndex = start;

					switch (row.type) {
						case GridLayoutRowType.PERCENT_100:
						case GridLayoutRowType.HERO:
						case GridLayoutRowType.FULL_WIDTH:
							start = start + 1;
							break;
						case GridLayoutRowType.PERCENT_50:
							start = start + 2;
							break;
					}

					const rowDatas = filteredDatas.slice(lastIndex, start);

					if (rowDatas) {
						return {
							data: SectionLayoutMappers.utils.mapDatas(store, rowDatas),
							type: SectionLayoutRowType[row.type],
						};
					}

					return null;
				})
				.filter((row) => !!row?.data?.length);
		},
	} as DataMapper,

	TeaserDataListComponentMapper: {
		getComponentName: (_content: CmsBackendContentEntry) => 'SectionLayoutDataList',
		isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
		map: (store: Store<RootState>, content: TeaserDataList): SectionLayoutDataListModel => ({
			data: SectionLayoutMappers.utils.mapDatas(
				store,
				SectionLayoutMappers.utils.filterDatas(store, content.datas),
			),
			uid: content.uid,
			useSliderOnMobile: !!content.useSliderOnMobile,
		}),
	} as DataMapper,

	SliderTeaserDataComponentMapper: {
		getComponentName: (_content: CmsBackendContentEntry) => 'Slider',
		isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
		map: (store: Store<RootState>, content: SliderTeaserData): SliderModel => {
			const { state: mediaqueryState } = useMediaqueryStore(store);
			let elementsInRow =
				mediaqueryState.device.category === MediaqueryDeviceCategory.DESKTOP
					? content.visibleDatasDesktop
					: undefined;

			if (!elementsInRow) {
				elementsInRow =
					mediaqueryState.device.category === MediaqueryDeviceCategory.TABLET
						? content.visibleDatasTablet
						: content.visibleDatasMobile;
			}

			return {
				options: {
					hasArrows: true,
					slidesPerView: content.visibleDatasMobile,
					slidesPerViewTablet: content.visibleDatasTablet,
					slidesPerViewDesktop: content.visibleDatasDesktop,
				},
				items: SectionLayoutMappers.utils
					.mapDatas(
						store,
						SectionLayoutMappers.utils.filterDatas(store, content.datas),
						elementsInRow,
					)
					.map((data) => ({
						uid: data.id,
						data: data.componentData,
						name: data.componentName,
					})),
				uid: content.uid,
			};
		},
	} as DataMapper,
};

// TESTIMONIAL
const TestimonialTeaserComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'Testimonial',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (store: Store<RootState>, content: TestimonialTeaserComponent): TestimonialModel => ({
		image: mapImage(store, content.image, {}, { width: 56 }, true),
		name: content.name,
		text: content.text,
		uid: content.uid,
		tracking: {
			view: (data) =>
				eecPromotionView([TestimonialTeaserComponentMapper.mapTrackingData(content, data)]),
			click: (data) =>
				eecPromotionClick(TestimonialTeaserComponentMapper.mapTrackingData(content, data)),
		},
	}),
	mapTrackingData: (content: TestimonialTeaserComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

// RICH-TEXT
const RichTextComponentMapper: DataMapper = {
	getComponentName: (_content: CmsBackendContentEntry) => 'RichText',
	isDynamicYieldComponent: (_content: CmsBackendContentEntry) => false,
	map: (_store: Store<RootState>, content: TextComponent): RichTextModel => ({
		headline: content.title,
		longDescription: content.text,
		shortDescription: content.teasingText,
		uid: content.uid,
		tracking: {
			view: (data) => eecPromotionView([RichTextComponentMapper.mapTrackingData(content, data)]),
			click: (data) => eecPromotionClick(RichTextComponentMapper.mapTrackingData(content, data)),
		},
	}),
	mapTrackingData: (content: TextComponent, trackingData) => ({
		...trackingData,
		id: content.trackingId || trackingData.id,
	}),
};

const mapText = (source: Text, deviceCategory: MediaqueryDeviceCategory | undefined) =>
	source
		? {
				color: FONTCOLOR[source.color],
				size:
					!source.size.mobileTextSize || deviceCategory === MediaqueryDeviceCategory.DESKTOP
						? FONTSIZE[source.size.desktopTextSize]
						: FONTSIZE[source.size.mobileTextSize],
				text: source.text,
			}
		: null;

// -------------------------------------------------------------------------------------------------

const mappers: {
	[componentName: string]: DataMapper;
} = {
	BarComponent: BarComponentMapper,
	BrandTeaserComponent: BrandTeaserComponentMapper,
	CategorySliderComponent: CategorySliderMapper,
	CategoryTeaserComponent: CategoryTeaserComponentMapper,
	CampaignTeaserComponent: CampaignTeaserComponentMapper,
	DYProductRecommendationComponent: DYProductRecommendationMapper,
	ImageTeaserComponent: ImageTeaserComponentMapper,
	PromotionTeaserComponent: PromotionTeaserComponentMapper,
	PromotionTextData: PromotionTextDataMapper,
	SectionGridLayoutComponent: SectionLayoutMappers.SectionLayoutComponentMapper,
	SliderTeaserData: SectionLayoutMappers.SliderTeaserDataComponentMapper,
	TeaserDataList: SectionLayoutMappers.TeaserDataListComponentMapper,
	TestimonialTeaserComponent: TestimonialTeaserComponentMapper,
	TextComponent: RichTextComponentMapper,
};
