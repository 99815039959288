import { Context } from '@nuxt/types';
import { useUserStore } from '~/@api/store/userApi';
import { PageTypes } from '~/@api/store.types';

export default async (context: Context) => {
	const { api: userApi, state: userState } = useUserStore(context.store);
	const localizedGender = getLocalizedGender(context);
	const gender =
		userState.user.genders.find((gender) => gender.name.toLowerCase() === localizedGender)?.code ||
		'NEUTRAL';

	if (gender && gender !== userState.user.gender?.code) {
		await userApi.setGender(gender);
	}
};

const getLocalizedGender = (context: Context): string => {
	if (
		[...(context.route.matched || [])].reverse().find((route) => route.meta?.pageType)?.meta
			.pageType === PageTypes.CATEGORY
	) {
		return context.route.params.categoryPath?.split('-')[0]?.toLowerCase();
	}
	return context.route.params.gender?.toLowerCase();
};
