import type { Store } from 'vuex';
import { useStoreModule, clientInitStoreModule } from '../useStoreModule';
import cartStore from './cart.store';
import cartApi from './cart.api';
import { CartState, RootState } from '~/@api/store.types';
import { CART } from '~/@constants/store';

export function useCartStore(store: Store<RootState>) {
	return useStoreModule<CartState, ReturnType<typeof cartApi>>(CART, store, cartStore, cartApi);
}

export async function clientInitCartStore(store: Store<RootState>): Promise<void> {
	return await clientInitStoreModule(CART, store, cartStore, cartApi);
}
