import { Context } from '@nuxt/types';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { elapsedTime } from '@/node_modules/@osp/utils/src/performance';

// eslint-disable-next-line require-await
export default async (context: Context) => {
	if (!process.client) return;

	/** In routes.ts are defined some global paths, which will be always inside "to.matched" and that's why they must be sorted out */
	if (
		(context.route.matched || []).filter(
			(route) => route.meta?.routeType !== 'template' && route.name !== 'defaultFallback',
		).length
	)
		return;

	const url = context.route.fullPath;

	Logger.info(`No route found for url '${url}', going to do a page-load!`);

	if (process.client && typeof window !== 'undefined') {
		window.location.href = url;

		// Stop navigation by redirecting to current route
		// Otherwise backend apis from subordinate middlewares would be called
		context.redirect(context.from);
	}

	elapsedTime('middleware: page-load-guard');
};
